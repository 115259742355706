@import url('https://fonts.googleapis.com/css2?family=Borel&display=swap');
/* Accueil */
a {
	text-decoration: none;
}

.login-page {
	width: 100%;
	height: 100vh;
	display: inline-block;
	display: flex;
	align-items: center;
}


.form-right,
.form-right:hover {
	background-color: #443ea2;

	i {
		font-size: 100px;
	}
}

/* Navbar */
:root {
	--font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont,
		"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--titre: 'Borel', cursive;
}

*,
*::before,
*::after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

nav {
	display: block;
}

body {
	margin: 0;
	font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #515151;
	text-align: left;
	background-color: #e9edf4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

a {
	color: #3f84fc;
	text-decoration: none;
	background-color: transparent;
}

a:hover {
	color: #0458eb;
	text-decoration: underline;
}


h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: "Borel", sans-serif;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}

h1,
.h1 {
	font-size: 2.5rem;
	font-weight: normal;
}

.dashboard {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 100vh;
}

.dashboard-app {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 2;
	-webkit-flex-grow: 2;
	-ms-flex-positive: 2;
	flex-grow: 2;
	margin-top: 84px;
}

.dashboard-content {
	-webkit-box-flex: 2;
	-webkit-flex-grow: 2;
	-ms-flex-positive: 2;
	flex-grow: 2;
	padding: 5px;
}

.dashboard-nav {
	min-width: 238px;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	overflow: auto;
	background-color: #373193;
}

.dashboard-compact .dashboard-nav {
	display: none;
}

.dashboard-nav header {
	min-height: 84px;
	padding: 8px 27px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dashboard-nav header .menu-toggle {
	display: none;
	margin-right: auto;
}

.dashboard-nav a {
	color: #515151;
}

.dashboard-nav a:hover {
	text-decoration: none;
}

.dashboard-nav {
	background-color: #443ea2;
}

.dashboard-nav a {
	color: #fff;
}

.brand-logo {
	font-family: "Nunito", sans-serif;
	font-weight: bold;
	font-size: 20px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	color: #515151;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.brand-logo:focus,
.brand-logo:active,
.brand-logo:hover {
	color: #dbdbdb;
	text-decoration: none;
}

.brand-logo i {
	color: #d2d1d1;
	font-size: 27px;
	margin-right: 10px;
}

.dashboard-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.dashboard-nav-item {
	min-height: 56px;
	padding: 8px 20px 8px 70px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	letter-spacing: 0.02em;
	transition: ease-out 0.5s;
}

.dashboard-nav-item i {
	width: 36px;
	font-size: 19px;
	margin-left: -40px;
}

.dashboard-nav-item:hover {
	background: rgba(255, 255, 255, 0.04);
}

.active {
	background: rgba(0, 0, 0, 0.1);
}

.dashboard-nav-dropdown {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.dashboard-nav-dropdown.show {
	background: rgba(255, 255, 255, 0.04);
}

.dashboard-nav-dropdown.show>.dashboard-nav-dropdown-toggle {
	font-weight: bold;
}

.dashboard-nav-dropdown.show>.dashboard-nav-dropdown-toggle:after {
	-webkit-transform: none;
	-o-transform: none;
	transform: none;
}

.dashboard-nav-dropdown.show>.dashboard-nav-dropdown-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.dashboard-nav-dropdown-toggle:after {
	content: "";
	margin-left: auto;
	display: inline-block;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid rgba(81, 81, 81, 0.8);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

.dashboard-nav .dashboard-nav-dropdown-toggle:after {
	border-top-color: rgba(255, 255, 255, 0.72);
}

.dashboard-nav-dropdown-menu {
	display: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.dashboard-nav-dropdown-item {
	min-height: 40px;
	padding: 8px 20px 8px 70px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	transition: ease-out 0.5s;
}

.dashboard-nav-dropdown-item:hover {
	background: rgba(255, 255, 255, 0.04);
}

.menu-toggle {
	position: relative;
	width: 42px;
	height: 42px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: white;
}

.menu-toggle:hover,
.menu-toggle:active,
.menu-toggle:focus {
	text-decoration: none;
	color: #875de5;
}

.menu-toggle i {
	font-size: 20px;
}

.dashboard-toolbar {
	min-height: 84px;
	background-color: #443ea2;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 8px 27px;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1000;
}

.nav-item-divider {
	height: 1px;
	margin: 1rem 0;
	overflow: hidden;
	background-color: rgba(236, 238, 239, 0.3);
}

@media (min-width: 992px) {
	.dashboard-app {
		margin-left: 238px;
	}

	.dashboard-compact .dashboard-app {
		margin-left: 0;
	}
}


@media (max-width: 768px) {
	.dashboard-content {
		padding: 15px 0px;
	}
}

@media (max-width: 992px) {
	.dashboard-nav {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 1070;
	}

	.dashboard-nav.mobile-show {
		display: block;
	}
}

@media (max-width: 992px) {
	.dashboard-nav header .menu-toggle {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
}

@media (min-width: 992px) {
	.dashboard-toolbar {
		left: 238px;
	}

	.dashboard-compact .dashboard-toolbar {
		left: 0;
	}
}

// Accueil 
.titre, .rw-container-header {
 font-family : var(--titre) ;
 font-size: 40px;
}
.rw-today-current {
	padding-bottom: 10px;
	padding-top: 10px;
}


.meteo {
	width: 500px;
	height: 150px;
}

.rw-container-left {
	background-color: #373193;
}

.rw-container-right {
	background-color: #373193;
}

.tableAppointmment {
	background-color: #373193;
}

// Modale add appointment
/* style.scss */
.custom-centered-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 80vh; /* Limitez la hauteur à 80% de la hauteur de la vue */
	overflow-y: auto; 
  }
  
  title {
	font-family: 'Sour Gummy', sans-serif;
	font-weight: 200; /* Si vous voulez spécifier un poids spécifique */
  }
  

.centered-dropdown .menu {
    text-align: center !important;  /* Utilisation de !important pour s'assurer que la règle est appliquée */
}

.centered-dropdown .item {
    text-align: center !important;  /* Pareil pour les items */
}

.centered-dropdown {
    width: 100%;  /* Pour s'assurer que le Dropdown occupe toute la largeur disponible */
    display: flex;
    justify-content: center;  /* Pour centrer le Dropdown lui-même */
}
/* Ajoutez ceci à votre style.scss */
.centered-input {
	display: block !important;
	margin-left: auto !important;
	margin-right: auto !important;
	width: 50% !important; /* Ajustez la largeur si nécessaire */
  }
  
  .custom-modal {
     height: auto;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
  }
  